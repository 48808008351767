import React from "react";
export default ({ path = "https://micetf.fr" }) => {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = `${path}/mentions-legales`;
    const handleClick = (e) => {
        e.preventDefault();
        a.click();
    };

    return (
        <button
            className="btn btn-primary my-2 mx-2"
            title="Mentions légales"
            onClick={handleClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                height="1em"
                fill="#f8f9fa"
            >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
        </button>
    );
};
